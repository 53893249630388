@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@700;800&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;    
}

.item-bold{
    line-height: 1;
}
.item-monospace{
    line-height: 1;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'news-701';
    src: url('./fonts/news-701-bt.ttf') format('truetype');
}

a {
    text-decoration: none;
}

* {
    box-sizing: border-box
}


html, body {
    height: 100%;
    overflow: hidden;
}

#app {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
}